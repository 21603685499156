<template>
  <popupcommon :attr='attr'>
    <div class="popup-main__bg">
      <img :src="img" alt="" />
    </div>
  </popupcommon>
</template>
<script>
import popupcommon from "@/components/popup/popupcommon.vue";
export default {
  components: { popupcommon },
  props: ["img",'attr'],
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>
